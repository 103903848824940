import React from 'react';
import { ref, get } from 'firebase/database';
import { database } from '../firebaseConfig';
import { CSVLink } from 'react-csv';

const ExportPicksButton = () => {
  const [csvData, setCsvData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchPicksData = async () => {
    setIsLoading(true);

    // Fetch picks data
    const picksRef = ref(database, 'picks');
    const picksSnapshot = await get(picksRef);
    const picksData = picksSnapshot.val();

    // Fetch users data
    const usersRef = ref(database, 'users');
    const usersSnapshot = await get(usersRef);
    const usersData = usersSnapshot.val();

    // Fetch games data
    const gamesRef = ref(database, 'games');
    const gamesSnapshot = await get(gamesRef);
    const gamesData = gamesSnapshot.val();

    if (picksData && usersData && gamesData) {
      const formattedData = [];
      Object.keys(picksData).forEach((userId) => {
        const user = usersData[userId];
        Object.keys(picksData[userId]).forEach((gameId) => {
          const pick = picksData[userId][gameId];
          const game = gamesData[gameId];
          if (game && user) { // Ensure game and user exist
            formattedData.push({
              name: user.name,
              email: user.email,
              homeTeam: game.homeTeam,
              awayTeam: game.awayTeam,
              weekNumber: game.weekNumber,
              pick: pick.pick,
              triplePlay: pick.triplePlay ? 'Yes' : 'No',
            });
          }
        });
      });
      setCsvData(formattedData);
    }
    setIsLoading(false);
  };

  return (
    <div className="mb-4">
      <button
        onClick={fetchPicksData}
        className="bg-accent text-gray-800 px-4 py-2 mt-4 rounded mb-2"
        disabled={isLoading}
      >
        {isLoading ? 'Loading...' : 'Export Picks'}
      </button>
      {csvData.length > 0 && (
        <button className="ml-4 bg-green-500 text-gray-800 px-4 py-2 rounded">
          <CSVLink
            data={csvData}
            filename={`picks_export_${new Date().toISOString()}.csv`}
          >
            Download CSV
          </CSVLink>
        </button>
      )}
    </div>
  );
};

export default ExportPicksButton;