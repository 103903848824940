import React, { useState, useEffect } from 'react';
import { auth, database } from '../firebaseConfig';
import { ref, push, onValue } from 'firebase/database';

function getColorFromUserId(userId) {
  let hash = 0;
  for (let i = 0; i < userId.length; i++) {
    hash = userId.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 70%, 50%)`; // Adjusted lightness for better text visibility
  return color;
}

function Messaging() {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const userRef = ref(database, `users/${auth.currentUser.uid}`);
    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      setUserName(data?.name || 'Anonymous');
    });

    const messagesRef = ref(database, 'messages');
    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      const messages = data ? Object.values(data) : [];
      setMessages(messages);
    });
  }, []);

  const handleSendMessage = () => {
    if (message.trim() === '') return;

    const userId = auth.currentUser.uid;
    const messagesRef = ref(database, 'messages');
    push(messagesRef, {
      userId,
      userName,
      message,
      timestamp: Date.now()
    }).catch(error => {
      console.error('Error setting new message:', error);
    });
    setMessage('');
  };

  return (
    <>
      <div className="max-w-md mx-auto pb-20 pt-4">
        <h2 className="text-2xl font-bold mb-4 text-accent pl-4">Messages</h2>
        <div className="flex flex-col items-center p-4 bg-gray-800 rounded-lg shadow-md w-full max-w-md h-full">
          <div className="flex-1 w-full overflow-y-auto mb-4">
            {messages.map((msg, index) => (
              <div key={index} className="mb-2 p-2 rounded">
                <strong
                  className="text-accent"
                  style={{ color: getColorFromUserId(msg.userId) }}
                >
                  {msg.userName}
                </strong>: {msg.message}
                <div className="text-xs text-gray-400">{new Date(msg.timestamp).toLocaleString()}</div>
              </div>
            ))}
          </div>
          <div className="flex w-full">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSendMessage();
                }
              }}
              className="flex-1 p-2 rounded-l-lg bg-gray-700 text-white focus:outline-none"
              placeholder="Type your message..."
            />
            <button
              onClick={handleSendMessage}
              className="p-2 bg-accent text-gray-800 rounded-r-lg hover:bg-accent-dark"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Messaging;