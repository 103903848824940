import React, { useEffect, useState, useContext } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebaseConfig';
import AuthContext from '../AuthContext';
import LeaderboardHeader from './LeaderboardHeader';
import LeaderboardTable from './LeaderboardTable';

const Leaderboard = () => {
  const { currentUser } = useContext(AuthContext);
  const [gamesByWeek, setGamesByWeek] = useState({});
  const [scores, setScores] = useState([]);
  const [users, setUsers] = useState({});
  const [userPicks, setUserPicks] = useState({});
  const [expandedUserId, setExpandedUserId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showWeeklyWinners, setShowWeeklyWinners] = useState(true);

  useEffect(() => {
    const fetchGames = () => {
      const gamesRef = ref(database, 'games');
      onValue(gamesRef, (snapshot) => {
        const data = snapshot.val();
        const groupedGames = data ? Object.keys(data).reduce((acc, id) => {
          const game = { id, ...data[id] };
          const week = game.weekNumber;
          if (!acc[week]) acc[week] = [];
          acc[week].push(game);
          return acc;
        }, {}) : {};
        setGamesByWeek(groupedGames);
      });
    };

    fetchGames();
  }, []);

  useEffect(() => {
    const fetchScores = () => {
      const scoresRef = ref(database, 'scores');
      onValue(scoresRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const scoresList = Object.keys(data).map((userId) => {
            const userScores = data[userId];
            const totalPoints = Object.values(userScores).reduce((acc, points) => acc + points, 0);

            return {
              userId,
              totalPoints,
              weeklyScores: userScores
            };
          });
          setScores(scoresList);
        }
      });
    };

    fetchScores();
  }, []);

  useEffect(() => {
    const fetchUsers = () => {
      const usersRef = ref(database, 'users');
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
        setUsers(data || {});
        if (currentUser && data[currentUser.uid]?.admin) {
          setIsAdmin(true);
        }
      });
    };

    fetchUsers();
  }, [currentUser]);

  useEffect(() => {
    const fetchAllUserPicks = () => {
      const picksRef = ref(database, 'picks');
      onValue(picksRef, (snapshot) => {
        const data = snapshot.val();
        setUserPicks(data || {});
      });
    };

    fetchAllUserPicks();
  }, []);

  const handleUserClick = (userId) => {
    if (expandedUserId === userId) {
      setExpandedUserId(null);
    } else {
      setExpandedUserId(userId);
    }
  };

  const weeks = Object.keys(gamesByWeek).map(Number).sort((a, b) => a - b);

  const getWeeklyTotal = (userScores, week) => {
    const games = gamesByWeek[week] || [];
    return games.reduce((total, game) => {
      const gameId = game.id;
      return total + (userScores[gameId] || 0);
    }, 0);
  };

  const sortedScores = [...scores].sort((a, b) => b.totalPoints - a.totalPoints);

  const highestScoresByWeek = weeks.reduce((acc, week) => {
    const games = gamesByWeek[week] || [];
    const allGamesLocked = games.every(game => game.locked);
    const highestScore = Math.max(...sortedScores.map(score => getWeeklyTotal(score.weeklyScores, week)));
    const allScoresZero = sortedScores.every(score => getWeeklyTotal(score.weeklyScores, week) === 0);

    if (allGamesLocked && !allScoresZero) {
      acc[week] = highestScore;
    } else {
      acc[week] = null;
    }
    return acc;
  }, {});

  return (
    <div className="max-w-4xl mx-auto pt-4">
      <LeaderboardHeader showWeeklyWinners={showWeeklyWinners} setShowWeeklyWinners={setShowWeeklyWinners} />
      <LeaderboardTable
        weeks={weeks}
        sortedScores={sortedScores}
        users={users}
        highestScoresByWeek={highestScoresByWeek}
        showWeeklyWinners={showWeeklyWinners}
        handleUserClick={handleUserClick}
        expandedUserId={expandedUserId}
        gamesByWeek={gamesByWeek}
        userPicks={userPicks}
        isAdmin={isAdmin}
        currentUser={currentUser}
      />
    </div>
  );
};

export default Leaderboard;