import React from 'react';
import { FaSyncAlt } from 'react-icons/fa';

const GameForm = ({
  homeTeam, setHomeTeam, awayTeam, setAwayTeam, homeSpread, setHomeSpread,
  awaySpread, setAwaySpread, dateTime, setDateTime, location, setLocation,
  weekNumber, setWeekNumber, winner, setWinner, locked, setLocked, active, setActive,
  saveGame, clearForm, selectedGameId, refreshScores
}) => {
  return (
    <div className="bg-gray-800 p-4 rounded shadow-md">
      <div className="grid grid-cols-10 gap-2 mb-2">
        <label className="col-span-4 text-white mb-2">
          Week Number
          <select
            value={weekNumber}
            onChange={(e) => setWeekNumber(e.target.value)}
            className="w-full h-10 p-2 rounded bg-gray-700 text-white"
          >
            {[...Array(15).keys()].map((num) => (
              <option key={num+1} value={num+1}>
                {num + 1}
              </option>
            ))}
          </select>
        </label>
        <label className="col-span-6 text-white mb-2">
          Date and Time
          <input
            type="datetime-local"
            value={dateTime}
            onChange={(e) => setDateTime(e.target.value)}
            className="w-full h-10 p-2 rounded bg-gray-700 text-white"
            step="300" // 5 minutes in seconds
          />
        </label>
      </div>
      <div className="grid grid-cols-10 gap-2 mb-2">
      <label className="col-span-6 text-white">
          Away Team
          <input
            type="text"
            value={awayTeam}
            onChange={(e) => setAwayTeam(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-white"
          />
        </label>
        <label className="col-span-4 text-white">
          Away Spread
          <input
            type="number"
            value={awaySpread}
            onChange={(e) => setAwaySpread(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-white"
          />
        </label>
      </div>
      <div className="grid grid-cols-10 gap-2 mb-2">
      <label className="col-span-6 text-white">
          Home Team
          <input
            type="text"
            value={homeTeam}
            onChange={(e) => setHomeTeam(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-white"
          />
        </label>
        <label className="col-span-4 text-white">
          Home Spread
          <input
            type="number"
            value={homeSpread}
            onChange={(e) => setHomeSpread(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-white"
          />
        </label>
      </div>
      <label className="block text-white mb-2">
        Location (City, State)
        <input
          type="text"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          className="w-full p-2 rounded bg-gray-700 text-white"
        />
      </label>
      <div className="grid grid-cols-10 gap-2 mb-2">
        <label className="col-span-6 text-white">
          Winner
          <select
            value={winner}
            onChange={(e) => setWinner(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-white"
          >
            <option value="">Select Winner</option>
            <option value="home">{homeTeam}</option>
            <option value="away">{awayTeam}</option>
            <option value="push">Push</option>
          </select>
        </label>
        <label className="col-span-2 text-white text-center">
          <span className="block mb-1">Locked:</span>
          <input
            type="checkbox"
            checked={locked}
            onChange={() => setLocked(!locked)}
            className="form-checkbox mx-auto"
            style={{ transform: 'scale(1.5)' }}
          />
        </label>
        <label className="col-span-2 text-white text-center">
          <span className="block mb-1">Active:</span>
          <input
            type="checkbox"
            checked={active}
            onChange={() => setActive(!active)}
            className="form-checkbox mx-auto"
            style={{ transform: 'scale(1.5)' }}
          />
        </label>
      </div>
      <div className="flex justify-between">
        <button
          onClick={saveGame}
          className="bg-accent text-gray-800 px-4 py-2 rounded hover:bg-accent-dark text-gray-800"
        >
          {selectedGameId ? 'Update' : 'Add'} Game
        </button>
        <button
          onClick={clearForm}
          className="text-accent px-4 py-2 rounded outline outline-1 outline-accent"
        >
          Clear
        </button>
        <button
          onClick={refreshScores}
          className="bg-transparent text-accent px-4 py-2 rounded"
        >
          <FaSyncAlt size={24} />
        </button>
      </div>
    </div>
  );
};

export default GameForm;