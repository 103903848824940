import React from 'react';
import LeaderboardRow from './LeaderboardRow';

const LeaderboardTable = ({ weeks, sortedScores, users, highestScoresByWeek, showWeeklyWinners, handleUserClick, expandedUserId, gamesByWeek, userPicks, isAdmin, currentUser }) => (
  <div className="overflow-x-auto">
    <table className="min-w-full bg-gray-800 text-white rounded shadow-md">
      <thead>
        <tr>
          <th className="px-4 py-2">Name</th>
          {weeks.map(week => (
            <th key={week} className="px-4 py-2 text-center">W{week}</th>
          ))}
          <th className="px-4 py-2 text-center">Total</th>
        </tr>
      </thead>
      <tbody>
        {sortedScores.map((score, index) => (
          <LeaderboardRow
            key={score.userId}
            score={score}
            index={index}
            weeks={weeks}
            users={users}
            highestScoresByWeek={highestScoresByWeek}
            showWeeklyWinners={showWeeklyWinners}
            handleUserClick={handleUserClick}
            expandedUserId={expandedUserId}
            gamesByWeek={gamesByWeek}
            userPicks={userPicks}
            isAdmin={isAdmin}
            currentUser={currentUser}
          />
        ))}
      </tbody>
    </table>
  </div>
);

export default LeaderboardTable;