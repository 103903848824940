// src/components/LogoutButton.js
import React, { useContext } from 'react';
import { FaSignOutAlt } from 'react-icons/fa';
import AuthContext from '../AuthContext';

const LogoutButton = () => {
  const { logout } = useContext(AuthContext);

  return (
    <button
      onClick={logout}
      className="bg-accent text-gray-800 px-4 py-2 rounded hover:bg-accent-dark flex items-center"
    >
      <FaSignOutAlt size={20} className="mr-2" />
      Logout
    </button>
  );
};

export default LogoutButton;
