import React, { useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { FaFootballBall, FaTrophy, FaUser, FaSignInAlt, FaUserPlus, FaTools, FaKey, FaComments } from 'react-icons/fa'; // Added FaComments
import SignUp from './components/SignUp';
import Login from './components/Login';
import AdminPanel from './components/AdminPanel';
import GameList from './components/GameList';
import UserProfile from './components/UserProfile';
import Leaderboard from './components/Leaderboard';
import Messaging from './components/Messaging'; // Import Messaging component
import AuthContext from './AuthContext';
import ResetPassword from './components/ResetPassword';
import { ref, onValue } from 'firebase/database';
import { database } from './firebaseConfig';
import './tailwind.css';

const App = () => {
  const { currentUser } = useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    if (currentUser) {
      const userRef = ref(database, `users/${currentUser.uid}`);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        setIsAdmin(data?.admin || false);
        setName(data?.name || '');
      });
    }
  }, [currentUser]);

  return (
    <Router>
      <AppContent currentUser={currentUser} isAdmin={isAdmin} name={name} />
    </Router>
  );
};

const AppContent = ({ currentUser, isAdmin, name }) => {
  const location = useLocation(); // Get the current location

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center dark">
      <div className="flex-1 pb-20 pt-4 w-full max-w-md overflow-y-auto pb-20"> {/* Added pb-20 for padding bottom */}
        <Routes>
          {!currentUser ? (
            <>
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<Login />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="*" element={<Login />} /> {/* Default route for non-logged in users */}
            </>
          ) : (
            <>
              <Route path="/profile" element={<UserProfile name={name} />} />
              <Route path="/games" element={<GameList />} />
              <Route path="/leaderboard" element={<Leaderboard />} />
              <Route path="/messaging" element={<Messaging />} /> {/* Added messaging route */}
              <Route path="/reset-password" element={<ResetPassword />} />
              {isAdmin && <Route path="/admin" element={<AdminPanel />} />}
              <Route path="/" element={<GameList />} /> {/* Default route for logged in users */}
            </>
          )}
        </Routes>
      </div>
      <nav className="bg-gray-800 p-6 fixed bottom-0 w-full max-w-md flex justify-around items-center">
        {currentUser ? (
          <>
            <Link to="/games" className={`flex flex-col items-center ${location.pathname === '/games' || location.pathname === '/' ? 'text-yellow-500' : 'text-accent hover:text-gray-200'}`}>
              <FaFootballBall size={30} />
            </Link>
            <Link to="/leaderboard" className={`flex flex-col items-center ${location.pathname === '/leaderboard' ? 'text-yellow-500' : 'text-accent hover:text-gray-200'}`}>
              <FaTrophy size={30} />
            </Link>
            <Link to="/messaging" className={`flex flex-col items-center ${location.pathname === '/messaging' ? 'text-yellow-500' : 'text-accent hover:text-gray-200'}`}>
              <FaComments size={30} />
            </Link>
            {isAdmin && (
              <Link to="/admin" className={`flex flex-col items-center ${location.pathname === '/admin' ? 'text-yellow-500' : 'text-accent hover:text-gray-200'}`}>
                <FaTools size={30} />
              </Link>
            )}
            <Link to="/profile" className={`flex flex-col items-center ${location.pathname === '/profile' ? 'text-yellow-500' : 'text-accent hover:text-gray-200'}`}>
              <FaUser size={30} />
            </Link>
          </>
        ) : (
          <>
            <Link to="/signup" className={`flex flex-col items-center ${location.pathname === '/signup' ? 'text-yellow-500' : 'text-accent hover:text-gray-200'}`}>
              <FaUserPlus size={30} />
            </Link>
            <Link to="/login" className={`flex flex-col items-center ${location.pathname === '/login' ? 'text-yellow-500' : 'text-accent hover:text-gray-200'}`}>
              <FaSignInAlt size={30} />
            </Link>
            <Link to="/reset-password" className={`flex flex-col items-center ${location.pathname === '/reset-password' ? 'text-yellow-500' : 'text-accent hover:text-gray-200'}`}>
              <FaKey size={30} />
            </Link>
          </>
        )}
      </nav>
    </div>
  );
};

export default App;