import React from 'react';

const LeaderboardHeader = ({ showWeeklyWinners, setShowWeeklyWinners }) => (
  <div className="flex justify-between items-center mb-4">
    <h2 className="text-2xl font-bold text-accent pl-4">Leaderboard</h2>
    <div className="flex items-center pr-4">
      <span className="text-white mr-2">Weekly High Score</span>
      <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
        <input
          type="checkbox"
          name="toggle"
          id="toggle"
          checked={showWeeklyWinners}
          onChange={() => setShowWeeklyWinners(!showWeeklyWinners)}
          className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer transition-transform duration-200 ease-in-out transform"
          style={{ transform: showWeeklyWinners ? 'translateX(1.25rem)' : 'translateX(0)' }}
        />
        <label
          htmlFor="toggle"
          className={`toggle-label block overflow-hidden h-6 rounded-full cursor-pointer ${showWeeklyWinners ? 'bg-accent' : 'bg-gray-300'}`}
        ></label>
      </div>
    </div>
  </div>
);

export default LeaderboardHeader;