// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCsHWJy-2U5ODF_9u5dZJlwLVHV1NI7PV4",
  authDomain: "pga-pickem.firebaseapp.com",
  databaseURL: "https://pga-pickem-default-rtdb.firebaseio.com",
  projectId: "pga-pickem",
  storageBucket: "pga-pickem.appspot.com",
  messagingSenderId: "775776378424",
  appId: "1:775776378424:web:0caa6581cbc264fd9d10cd"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

// Set persistence
setPersistence(auth, browserLocalPersistence);

export { auth, database };
