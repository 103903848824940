import React from 'react';
import UserDetails from './UserDetails';

const LeaderboardRow = ({ score, index, weeks, users, highestScoresByWeek, showWeeklyWinners, handleUserClick, expandedUserId, gamesByWeek, userPicks, isAdmin, currentUser }) => {
  const getWeeklyTotal = (userScores, week) => {
    const games = gamesByWeek[week] || [];
    return games.reduce((total, game) => {
      const gameId = game.id;
      return total + (userScores[gameId] || 0);
    }, 0);
  };

  return (
    <>
      <tr
        onClick={() => handleUserClick(score.userId)}
        className={`cursor-pointer hover:bg-gray-700 ${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-700'}`}
      >
        <td className="border-t border-gray-700 px-4 py-2">{users[score.userId]?.name || 'Unknown'}</td>
        {weeks.map(week => {
          const weeklyTotal = getWeeklyTotal(score.weeklyScores, week);
          const isHighest = weeklyTotal === highestScoresByWeek[week];
          return (
            <td
              key={week}
              className={`border-t border-gray-700 px-4 py-2 text-center ${showWeeklyWinners && isHighest ? 'bg-green-500' : ''}`}
            >
              {weeklyTotal}
            </td>
          );
        })}
        <td className="border-t border-gray-700 px-4 py-2 text-center">{score.totalPoints}</td>
      </tr>
      {expandedUserId === score.userId &&
        <UserDetails
          weeks={weeks}
          score={score}
          gamesByWeek={gamesByWeek}
          userPicks={userPicks}
          isAdmin={isAdmin}
          currentUser={currentUser}
        />
      }
    </>
  );
};

export default LeaderboardRow;