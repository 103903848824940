import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail, signOut } from 'firebase/auth'; // Import signOut
import { auth } from '../firebaseConfig';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent!');
      setError(null);
      await signOut(auth); // Log out the user
      setTimeout(() => navigate('/login'), 3000); // Redirect to login after 3 seconds
    } catch (error) {
      setError(error.message);
      setMessage(null);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white">
      <div className="bg-gray-800 p-8 rounded-lg shadow-md w-full max-w-md">
      <img src={process.env.PUBLIC_URL + "/pwf-logo-full.png"} alt="Logo" className="mx-auto mb-6 w-32 h-32" />
        <h2 className="text-2xl font-bold mb-6 text-left">Reset Password</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {message && <p className="text-green-500 mb-4">{message}</p>}
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="w-full p-2 mb-4 bg-gray-700 rounded text-white focus:outline-none focus:ring-2 focus:ring-accent"
        />
        <button
          onClick={handleResetPassword}
          className="w-full bg-accent hover:bg-accent-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-accent"
        >
          Reset Password
        </button>
      </div>
    </div>
  );
};

export default ResetPassword;