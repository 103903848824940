import React, { useState, useEffect } from 'react';
import { ref, set, push, onValue, update, get, remove } from 'firebase/database';
import { database } from '../firebaseConfig';
import GameForm from './GameForm';
import AdminGameList from './AdminGameList';
import ExportPicksButton from './ExportPicksButton';

const AdminPanel = () => {
  const [games, setGames] = useState({});
  const [selectedGameId, setSelectedGameId] = useState(null);
  const [homeTeam, setHomeTeam] = useState('');
  const [awayTeam, setAwayTeam] = useState('');
  const [homeSpread, setHomeSpread] = useState('');
  const [awaySpread, setAwaySpread] = useState('');
  const [dateTime, setDateTime] = useState('');
  const [location, setLocation] = useState('');
  const [weekNumber, setWeekNumber] = useState('');
  const [winner, setWinner] = useState('');
  const [locked, setLocked] = useState(false);
  const [active, setActive] = useState(true);
  const [confirmationStatus, setConfirmationStatus] = useState(null);

  useEffect(() => {
    const gamesRef = ref(database, 'games');
    onValue(gamesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const groupedGames = Object.keys(data).reduce((acc, id) => {
          const game = { id, ...data[id] };
          const week = parseInt(game.weekNumber, 10); // Ensure weekNumber is an integer
          if (!acc[week]) acc[week] = [];
          acc[week].push(game);
          return acc;
        }, {});

        // Sort weeks in descending order
        const sortedGroupedGames = Object.keys(groupedGames)
          .map(Number) // Convert week numbers to integers
          .sort((a, b) => b - a) // Sort in descending order
          .reduce((acc, week) => {
            acc[week] = groupedGames[week];
            return acc;
          }, {});

        setGames(sortedGroupedGames);
      } else {
        setGames({});
      }
    });
  }, []);

  const updateScoresAndPicks = async (gameId, winner) => {
    const picksRef = ref(database, 'picks');
    const scoresRef = ref(database, 'scores');

    const picksSnapshot = await get(picksRef);
    const picksData = picksSnapshot.val();

    const scoresSnapshot = await get(scoresRef);
    const scoresData = scoresSnapshot.val() || {};

    if (picksData) {
      Object.keys(picksData).forEach((userId) => {
        const userPicks = picksData[userId];
        if (userPicks[gameId]) {
          const userPick = userPicks[gameId];
          const isCorrectPick = userPick.pick === winner || winner === 'push';
          const points = isCorrectPick ? (userPick.triplePlay ? 3 : 1) : 0; // Check for triple play

          // Update scores
          if (!scoresData[userId]) {
            scoresData[userId] = {};
          }
          scoresData[userId][gameId] = points;
        }
      });

      // Update the scores in the database
      await set(scoresRef, scoresData);
    }
  };

  const refreshScores = async () => {
    const gamesRef = ref(database, 'games');
    const gamesSnapshot = await get(gamesRef);
    const gamesData = gamesSnapshot.val();

    if (gamesData) {
      for (const gameId in gamesData) {
        const game = gamesData[gameId];
        await updateScoresAndPicks(gameId, game.winner);
      }
    }
  };

  const saveGame = async () => {
    const gameData = {
      homeTeam,
      awayTeam,
      homeSpread: parseFloat(homeSpread),
      awaySpread: parseFloat(awaySpread),
      triplePlay: false,
      dateTime,
      location,
      weekNumber: parseInt(weekNumber, 10),
      winner,
      locked,
      active
    };

    if (selectedGameId) {
      // Update existing game
      await update(ref(database, `games/${selectedGameId}`), gameData);
      await updateScoresAndPicks(selectedGameId, winner);
    } else {
      // Add new game
      const newGameRef = push(ref(database, 'games'));
      await set(newGameRef, gameData);
      await updateScoresAndPicks(newGameRef.key, winner);
    }

    clearForm();
  };

  const deleteGame = async (gameId) => {
    // Add confirmation message
    setConfirmationStatus({
      type: 'confirmation',
      message: 'Are you sure you want to delete this game? Associated Scores and Picks will be deleted as well. Consider editing the game if possible.',
      onConfirm: async () => {
        // Remove the game
        await remove(ref(database, `games/${gameId}`));

        // Remove associated picks
        const picksRef = ref(database, 'picks');
        const picksSnapshot = await get(picksRef);
        const picksData = picksSnapshot.val();
        if (picksData) {
          Object.keys(picksData).forEach(async (userId) => {
            if (picksData[userId][gameId]) {
              delete picksData[userId][gameId];
            }
          });
          await set(picksRef, picksData);
        }

        // Remove associated scores
        const scoresRef = ref(database, 'scores');
        const scoresSnapshot = await get(scoresRef);
        const scoresData = scoresSnapshot.val();
        if (scoresData) {
          Object.keys(scoresData).forEach(async (userId) => {
            if (scoresData[userId][gameId]) {
              delete scoresData[userId][gameId];
            }
          });
          await set(scoresRef, scoresData);
        }

        // Update the state
        setGames((prevGames) => {
          const updatedGames = { ...prevGames };
          Object.keys(updatedGames).forEach((week) => {
            updatedGames[week] = updatedGames[week].filter((game) => game.id !== gameId);
            if (updatedGames[week].length === 0) {
              delete updatedGames[week];
            }
          });
          return updatedGames;
        });

        // Clear confirmation status
        setConfirmationStatus(null);
      },
      onCancel: () => {
        setConfirmationStatus(null);
      }
    });
  };

  const editGame = (game) => {
    setSelectedGameId(game.id);
    setHomeTeam(game.homeTeam);
    setAwayTeam(game.awayTeam);
    setHomeSpread(game.homeSpread);
    setAwaySpread(game.awaySpread);
    setDateTime(game.dateTime);
    setLocation(game.location);
    setWeekNumber(game.weekNumber);
    setWinner(game.winner || '');
    setLocked(game.locked);
    setActive(game.active);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const clearForm = () => {
    setSelectedGameId(null);
    setHomeTeam('');
    setAwayTeam('');
    setHomeSpread('');
    setAwaySpread('');
    setDateTime('');
    setLocation('');
    setWeekNumber('');
    setWinner('');
    setLocked(false);
    setActive(true);
  };

  const massUpdateGames = async (week, field) => {
    const gamesRef = ref(database, 'games');
    const gamesSnapshot = await get(gamesRef);
    const gamesData = gamesSnapshot.val();

    if (gamesData) {
      const updates = {};
      Object.keys(gamesData).forEach((gameId) => {
        const game = gamesData[gameId];
        if (game.weekNumber === week) {
          if (field === 'active') {
            updates[`games/${gameId}/active`] = !game.active;
          } else if (field === 'locked') {
            updates[`games/${gameId}/locked`] = !game.locked;
          }
        }
      });

      await update(ref(database), updates);
    }
  };

  return (
    <div className="max-w-md mx-auto pb-10 pt-4">
      {confirmationStatus && (
        <>
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-gray-800 border-2 border-accent p-4 rounded text-white text-center max-w-sm">
              {confirmationStatus.message}
              <div className="mt-2">
                <button onClick={confirmationStatus.onConfirm} className="text-accent px-4 py-2 rounded outline outline-1 outline-accent mr-4">Delete Forever</button>
                <button onClick={confirmationStatus.onCancel} className="bg-accent text-gray-800 px-4 py-2 rounded">No</button>
              </div>
            </div>
          </div>
        </>
      )}
      <h2 className="text-2xl font-bold mb-4 text-accent pl-4">Admin Panel</h2>
      <GameForm
        homeTeam={homeTeam}
        setHomeTeam={setHomeTeam}
        awayTeam={awayTeam}
        setAwayTeam={setAwayTeam}
        homeSpread={homeSpread}
        setHomeSpread={setHomeSpread}
        awaySpread={awaySpread}
        setAwaySpread={setAwaySpread}
        dateTime={dateTime}
        setDateTime={setDateTime}
        location={location}
        setLocation={setLocation}
        weekNumber={weekNumber}
        setWeekNumber={setWeekNumber}
        winner={winner}
        setWinner={setWinner}
        locked={locked}
        setLocked={setLocked}
        active={active}
        setActive={setActive}
        saveGame={saveGame}
        clearForm={clearForm}
        selectedGameId={selectedGameId}
        refreshScores={refreshScores}
      />
      <br />
      <h3 className="text-xl font-bold mt-4 mb-2 text-accent pl-4">Existing Games</h3>
      <AdminGameList
        games={games}
        editGame={editGame}
        deleteGame={deleteGame}
        massUpdateGames={massUpdateGames}
      />
      <ExportPicksButton />
    </div>
  );
};

export default AdminPanel;