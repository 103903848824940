// src/components/Login.js
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { auth } from "../firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/"); // Redirect to home path
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white">
      <div className="bg-gray-800 p-8 rounded-lg shadow-md w-full max-w-md">
        <img src={process.env.PUBLIC_URL + "/pwf-logo-full.png"} alt="Logo" className="mx-auto mb-6 w-32 h-32" />
        <h2 className="text-2xl font-bold mb-6 text-left">Login</h2>
        {error && (
          <div className="mb-4">
            <p className="text-red-500">{error}</p>
            <div className="flex justify-between mt-2">
              <Link to="/signup" className="text-accent hover:underline">Sign Up</Link>
              <Link to="/reset-password" className="text-accent hover:underline">Reset Password</Link>
            </div>
          </div>
        )}
        <form onSubmit={handleLogin}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="w-full p-2 mb-4 bg-gray-700 rounded text-white focus:outline-none focus:ring-2 focus:ring-accent"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="w-full p-2 mb-6 bg-gray-700 rounded text-white focus:outline-none focus:ring-2 focus:ring-accent"
          />
          <button
            type="submit"
            className="w-full bg-accent hover:bg-accent-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-accent"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
