import React, { useState, useEffect } from 'react';
import GameItem from './GameItem';
import { Tabs, Tab } from './Tabs';

const AdminGameList = ({ games, editGame, deleteGame, massUpdateGames }) => {
  const [highestWeek, setHighestWeek] = useState(1);

  useEffect(() => {
    setHighestWeek(Math.max(...Object.keys(games).map(Number)));
  }, [games]);

  return (
    <Tabs highestWeek={highestWeek}>
      {Object.keys(games).map((week) => (
        <Tab key={week} label={`Week ${week}`}>
          <button
            onClick={() => massUpdateGames(parseInt(week, 10), 'locked')}
            className="text-accent px-4 py-2 rounded mb-4 mr-4 outline outline-1 outline-accent"
          >
            {games[week].some(game => game.locked) ? 'Unlock All Games' : 'Lock All Games'}
          </button>
          <button
            onClick={() => massUpdateGames(parseInt(week, 10), 'active')}
            className="text-accent px-4 py-2 rounded mb-4 outline outline-1 outline-accent"
          >
            {games[week].some(game => game.active) ? 'Deactivate All Games' : 'Activate All Games'}
          </button>
          <ul className="list-disc list-inside">
            {games[week].map((game) => (
              <GameItem key={game.id} game={game} editGame={editGame} deleteGame={deleteGame} />
            ))}
          </ul>
        </Tab>
      ))}
    </Tabs>
  );
};

export default AdminGameList;