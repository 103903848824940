// src/components/UserProfile.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../AuthContext';
import LogoutButton from './LogoutButton';

const UserProfile = ({ name }) => {
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) {
    return <div className="text-center text-red-500">No user is logged in</div>;
  }

  return (
    <div className="max-w-md mx-auto pb-20 pt-4">
      <h2 className="text-2xl font-bold mb-4 text-accent pl-4">Profile</h2>
      <div className="bg-gray-800 p-4 rounded shadow-md">
        <p className="mb-1">Name: {name}</p>
        <p className="mb-4">Email: {currentUser.email}</p>
        <div className="flex justify-between items-center mt-4">
          <LogoutButton />
          <Link to="/reset-password" className="text-accent hover:underline">Reset Password</Link>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
